import { userAxios } from '../index'

const configList = async (data) => {
    try {
        return await userAxios.get('config_app', { data }).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
const configLoginUpdate = async (id, data) => {
    try {
        return await userAxios.put(`config_app/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
const configActive = async (data) => {
    try {
        return await userAxios.get('config_apps/config-active', { data }).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const confiLogoActive = async () => {
    try {
        return await userAxios.get('logo/logo-active').then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const configLogoUpdate = async (id, data, config = null) => {
    try {
        if(config){
            return await userAxios.post(`logos/${id}?_method=PUT`, data, {
                headers: {
                    'Content-Type' : 'multipart/form-data'
                }
            }).then(res => res.data)
        }
        return await userAxios.put(`logos/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    configList,
    configActive,
    configLoginUpdate,
    confiLogoActive,
    configLogoUpdate
}