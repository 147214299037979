<template>
  <img
    :src="imageLogo != null ? imageLogo : '@/assets/images/logo/logo360_vertical.svg'"
    width="80px"
    alt=""
    srcset=""
  />
</template>

<script>
import axiosCT from "@/services/admin/config";
export default {
  created() {
    axiosCT.confiLogoActive().then(({ dir_img }) => {
      this.imageLogo = dir_img;
      localStorage.setItem("imageLogo", dir_img);
    });
  },
  data() {
    return {
      imageLogo: null,
    };
  },
};
</script>
